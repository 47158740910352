<template>
    <v-container fluid class="fill-height text-center mt-0 pt-0">
        <v-card class="fill-height container--fluid" width="100vw" flat>
            <v-row>
                <!--class="info d-none d-md-flex align-center justify-center"-->
               <!-- <v-col cols="12" class="info" v-show="false">
                    <v-toolbar dense flat class="transparent mb-1" height="25" dark>
                        <v-toolbar-title>
                            <span class="headline text-uppercase">Datos de la Organización</span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                </v-col>-->
                <v-col cols="12" class="align-center justify-center">
                    <v-card>
                        <v-form ref="form" v-model="valid" lazy-validation action="/admin/wireframe">
                            <v-card-title class="primary text-h6 grey lighten-2">
                                <span class="headline white--text">{{ formTitle }}</span>
                            </v-card-title>
                            <v-container fluid class="text-center">
                                <v-row class="mt-0">
                                    <v-col cols="12" md="8">
                                        <v-text-field
                                                v-model="editedCompany.name"
                                                label="Nombre"
                                                :rules="textRules"
                                                :disabled="saveChanges"
                                                :loading="loadingData"
                                                required
                                        ></v-text-field>
                                        <v-text-field
                                                v-model="editedCompany.address"
                                                label="Dirección"
                                                :rules="textRules"
                                                :loading="loadingData"
                                                :disabled="saveChanges"
                                                required
                                        ></v-text-field>
                                        <v-text-field
                                                v-model="editedCompany.email"
                                                :rules="emailRules"
                                                label="E-mail"
                                                :loading="loadingData"
                                                :disabled="saveChanges"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-row no-gutters align="center" justify="center">
                                            <v-img
                                                :src="imagePreview != null ? imagePreview : require('@/assets/images/no-image.jpg')"
                                                :lazy-src="require('@/assets/images/no-image.jpg')"
                                                max-height="150"
                                                max-width="150"
                                                :loading="loadingData"
                                                :aspect-ratio="1"
                                                class="white--text align-center justify-center elevation-2">
                                            </v-img>
                                        </v-row>
                                        <v-row no-gutters align="center" justify="center">
                                            <v-file-input
                                                v-model="imageFile"
                                                accept="image/png, image/jpeg, image/bmp"
                                                show-size
                                                :disabled="saveChanges"
                                                clearable
                                                :loading="loadingData"
                                                label="Cargar/Cambiar imágen"
                                                placeholder="Archivo de imágen"
                                                prepend-icon="mdi-camera"
                                                @change="changePreviewImage()">
                                            </v-file-input>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field-simplemask
                                            v-model="editedCompany.phone1"
                                            label="Teléfono"
                                            :loading="loadingData"
                                            :disabled="saveChanges"
                                            v-bind:properties="{
                                                        dense: true,
                                                        prefix: '+52',
                                                        suffix: '',
                                                        readonly: false,
                                                        disabled: false,
                                                        outlined: false,
                                                        clearable: false,
                                                        placeholder: '',
                                                        required: true,
                                                        rules: textRules
                                                    }"
                                            v-bind:options="{
                                                        inputMask: '(###) ###-####',
                                                        outputMask: '##########',
                                                        empty: null,
                                                        applyAfter: false,
                                                        alphanumeric: false,
                                                        lowerCase: false,
                                                    }"
                                            v-bind:focus="focus"
                                            v-on:focus="focus = false"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <!--rules: textRules-->
                                        <v-text-field-simplemask
                                                v-model="editedCompany.phone2"
                                                label="Teléfono"
                                                :loading="loadingData"
                                                :disabled="saveChanges"
                                                v-bind:properties="{
                                                        dense: true,
                                                        prefix: '+1',
                                                        suffix: '',
                                                        readonly: false,
                                                        disabled: false,
                                                        outlined: false,
                                                        clearable: false,
                                                        placeholder: '',
                                                        required: true,
                                                        rules: textRules
                                                    }"
                                                v-bind:options="{
                                                        inputMask: '(###) ###-####',
                                                        outputMask: '##########',
                                                        empty: null,
                                                        applyAfter: false,
                                                        alphanumeric: false,
                                                        lowerCase: false,
                                                    }"
                                                v-bind:focus="focus"
                                                v-on:focus="focus = false"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                                dense
                                                :loading="loadingData"
                                                :disabled="saveChanges"
                                                v-model="editedCompany.web_page"
                                                label="Sitio Web"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                                dense
                                                :loading="loadingData"
                                                :disabled="saveChanges"
                                                v-model="editedCompany.facebook_profile"
                                                label="Perfil en Facebook"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                                dense
                                                :loading="loadingData"
                                                :disabled="saveChanges"
                                                v-model="editedCompany.instagram_profile"
                                                label="Perfil en Instagram"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                                dense
                                                :loading="loadingData"
                                                :disabled="saveChanges"
                                                v-model="editedCompany.youtube_video"
                                                label="Canal de Youtube"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn :disabled="!valid || loadingData" color="primary" :loading="saveChanges" @click="save">
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <v-snackbar
                v-model="snackBar"
                :timeout="2000">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn  v-bind="attrs"
                        icon
                        @click="snackBar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
    import companyService from "@/providers/CompanyService";
    import { changeInformationSEO } from "@/providers/KeywordsServices";
    
    export default {
        name: "CompanyComponent",
        data: () => ({
            snackBar: false,
            snackText: '',
            focus: false,
            valid: true,
            loadingData: true,
            imageFile: null,
            imagePreview: null,
            textRules: [
                v => !!v || 'Los datos de este campo son obligatorio!'
            ],
            emailRules: [
                v => !!v || "El Correo Electrónico es obligatorio",
                v => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido"
            ],
            imageRules: [
                value => !value || value.size < 2000000 || "El tamaño de la imagen no debe de sobrepasar los 2 MB!",
                // this.imagePreview == null || 'La imagen asociada es obligatoria!'
                v => {
                    if (this.imagePreview || this.imagePreview == null) {
                        return 'La imagen asociada es obligatoria!'
                    }
                    if (this.imagePreview && v) {
                        return false
                    }
                    return "Either fill out a complete image, or no image at all."
                }
            ],
            facebookRules: [
                f => !f ? true : this.regexMatchesText('facebook', f) || 'Enter a valid facebook url'
            ],
            editedIndex: -1,
            editedCompany: {
                id: 0,
                name: "",
                address: "",
                email: "",
                web_page: "",
                phone1: "",
                phone2: "",
                facebook_profile: "",
                instagram_profile: "",
                youtube_video: "",
                logo_url: ""
            },
            defaultCompany: {
                id: 0,
                name: "",
                address: "",
                email: "",
                web_page: "",
                phone1: "",
                phone2: "",
                facebook_profile: "",
                instagram_profile: "",
                youtube_video: "",
                logo_url: ""
            },
            saveChanges: false,
        }),
        computed: {
            formTitle() {
                return "Datos de la Organización";
            }
        },
        methods: {
            getCompany() {
                this.loadingData = true;
                companyService.getViewData().then(record => {
                    if (record.value !== null) {
                        this.editedCompany = record.value;
                        this.editedIndex = this.editedCompany.id;
                        if (this.editedCompany.logo_url !== null) {
                            this.imagePreview = this.editedCompany.logo_url;
                        } else {
                            this.imagePreview = null;
                        }
                        this.imageFile = null;
                    } else {
                        this.editedCompany = this.defaultCompany;
                        this.newItem();
                    }
                }).catch(()=>{
                    this.snackText = "Ha ocurrido un error!";
                    this.snackBar = true;
                }).finally(()=>{
                    this.loadingData = false;
                });
            },
            newItem() {
                this.editedIndex = -1;
                this.imagePreview = null;
                this.imageFile = null;
                this.valid = false;
            },
            save() {
                if (this.imagePreview == null) {
                    this.snackText = 'El formulario no contiene un logo asociado. Por favor agrege uno!!';
                    this.snackBar = true;
                    return;
                }
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    this.saveChanges = true;
                    if (this.editedIndex > -1) {
                        companyService.updateRecord(this.editedCompany, this.imageFile).then(result => {
                            if (result.success) {
                                this.getCompany();
                                this.snackText = 'Datos del formulario guardado con éxito...';
                            } else {
                                this.snackText = "Ha ocurrido un error!";
                            }
                        }).catch(()=>{
                            this.snackText = "Ha ocurrido un error!";
                        }).finally(()=>{
                            this.snackBar = true;
                            this.saveChanges = false;
                        });
                    } else {
                        companyService.addRecord(this.editedCompany, this.imageFile).then(result => {
                            if (result.success) {
                                this.snackText = 'Datos del formulario guardado con éxito...';
                                this.getCompany();
                            } else {
                                this.snackText = "Ha ocurrido un error!";
                            }
                        }).catch(()=>{
                            this.snackText = "Ha ocurrido un error!";
                        }).finally(()=>{
                            this.snackBar = true;
                            this.saveChanges = false;
                        });
                    }
                }
            },
            changePreviewImage() {
                if (this.imageFile) {
                    let reader = new FileReader();
                    reader.onload = () => {
                        this.imagePreview = reader.result;
                    };
                    reader.readAsDataURL(this.imageFile);
                    this.snackText = 'Imagen de perfil cargada con éxito...';
                    this.snackBar = true;
                }
            },
        },
        mounted() {
            this.editedCompany = this.defaultCompany;
            this.getCompany();
            changeInformationSEO();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
</style>